/* eslint-disable */
import React from "react";

export default {
  en: {
    quote: "For me, it's really easy",
    author: "Olivier Menir, Director",
    sector: "Online medical appointment scheduling and teleconsultation",
    interviewees: ["Olivier Menir, Director"],
    service: "Dynamic Maps",
    geo: "France",
    blocks: [
      {
        title: "LeMedecin.fr",
        description: (
          <p>
            Olivier Menir runs the company{" "}
            <a
              href="https://lemedecin.fr/"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              LeMedecin.fr
            </a>{" "}
            , which aims to support patients at each stage of their treatment.
            For this purpose, the platform offers a number of services including
            online appointment scheduling and remote-consultations. Today there
            are almost 3 million unique users.
          </p>
        ),
        img: "clientLogo",
      },
      {
        title: "LeMedecin.fr & Jawg",
        description: (
          <p>
            LeMedecin.fr is mainly a directory of health professionals and uses
            Jawg specifically on the results pages to show nearby practitioners.
            At first, the code snippet used a free version of Jawg on the
            website. As traffic grew significantly, they switched to a paid plan
            seamlessly.{" "}
            <q>
              I also used Google Maps in some of our pages, and it’s true that
              since they changed their pricing, we only kept Jawg who already
              prevailed beyond the technology
            </q>
            .
          </p>
        ),
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <span>
            <q>
              Since the beginning, Jawg always provided a real support process
              and offered me a plan that suited my needs
            </q>
            , says Olivier,{" "}
            <q>
              I really appreciate this kind of relationship that is very fair
            </q>
            . Beyond the commercial side, it is the aesthetic aspect that also
            seduced LeMedecin.fr:{" "}
            <q>
              The default theme is very neat, it is very satisfying in terms of
              customer experience
            </q>
            . For Olivier, the implementation of Jawg{" "}
            <q>is really easy : one API, one line of code</q>.
          </span>
        ),
      },
      {
        title: "Jawg Maps in 3 words?",
        description: "Easy, Fair, Partner",
      },
    ],
  },
  fr: {
    quote: "Pour moi c’est vraiment la facilité",
    author: "Olivier Menir, Dirigeant",
    sector: "Plateforme de prise de rendez-vous médical et de téléconsultation",
    interviewees: ["Olivier Menir, Dirigeant"],
    service: "Dynamic Maps",
    geo: "France",
    blocks: [
      {
        title: "LeMedecin.fr",
        description: (
          <p>
            Olivier Menir dirige la société{" "}
            <a
              href="https://lemedecin.fr/"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              LeMedecin.fr
            </a>{" "}
            qui est une société qui vise à accompagner le patient à chacune des
            étapes de son parcours de soin et qui pour ça propose un certain
            nombre de services dont la prise de rendez-vous en ligne et la
            téléconsultation pour les patients. Aujourd’hui il y a pratiquement
            3 millions de visiteurs uniques qui se connectent au site.
          </p>
        ),
        img: "clientLogo",
      },
      {
        title: "LeMedecin.fr & Jawg",
        description: (
          <p>
            LeMedecin.fr utilise Jawg de façon spécifique sur les fiches de
            résultats car c’est notamment un annuaire de professionnel de santé
            et qu’il faut représenter les praticiens qui sont autour de
            l’utilisateur. Un template contenant Jawg avait été intégré sur le
            site web, et il s’est avéré être intéressant de passer à un plan
            payant lorsque le trafic a augmenté de façon notable.{" "}
            <q>
              &#160;J’utilisais Google Maps pour toute une partie des pages et
              c’est vrai que depuis leur changement de grille tarifaire, Jawg
              s’est définitivement imposé au delà de la technologie&#160;
            </q>
            .
          </p>
        ),
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <span>
            <q>
              &#160;Il y a eu une vrai démarche d’accompagnement au début pour
              me proposer un forfait qui me convenait&#160;
            </q>
            , souligne Olivier,{" "}
            <q>
              &#160;j’apprécie vraiment ce type de relation qui est tres
              fair&#160;
            </q>
            . Au delà de l’aspect commercial, c’est l’aspect esthétique qui
            séduit aussi chez LeMedecin.fr :{" "}
            <q>
              &#160;le thème de base est très propre et donc en terme
              d’expérience client c’est très satisfaisant&#160;
            </q>
            . Pour Olivier, l’implémentation de Jawg{" "}
            <q>
              &#160;c’est vraiment la facilité : une API, une ligne de
              code&#160;
            </q>
            .
          </span>
        ),
      },
      {
        title: "Jawg Maps en 3 mots ?",
        description: "Facile, Fair, Partner",
      },
    ],
  },
};
